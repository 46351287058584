<!--  -->
<template>
  <div class="head-cont">
    <div class="order-state">
      <div style="display: flex;align-items: center">
        <img src="../../assets/images/orderState/icon_01.png"  v-if="state === 0"/>
        <img src="../../assets/images/orderState/icon_02.png"  v-else-if="state === 10 || state === 20"/>
        <img src="../../assets/images/orderState/icon_03.png"  v-else-if="state === 100"/>
        <img src="../../assets/images/orderState/icon_04.png"  v-else-if="state === 30" />
        <img src="../../assets/images/orderState/icon_05.png"  v-else-if="state === 99" />
        <span class="icon-sl" style="margin-right: 5px"></span>
        {{stateTxt}}
        <div v-if="state === 0 && (time - Date.now() > 0)" style="display: flex;align-items: flex-start;color: #fff;font-size: 10px;margin-left: 15px;position: relative;top: 3px">
          <van-count-down style="color:#fff;font-size: 10px;position: relative;bottom: 1px" :time="time - Date.now()" />
          后自动取消
        </div>
      </div>
    </div>
    <div class="address" v-if="addrInfo">
      <div class="icon-point">
        <span class="icon-dw"></span>
      </div>
      <div class="addr-cont">
        <p>
          <span>收货人：{{addrInfo.name}}</span><span>{{addrInfo.phone}}</span>
        </p>
        <p>收货地址：{{addrInfo.fullAddress}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadPublic',
  props: {
    state: {
      type: Number,
      default: 0
    },
    stateTxt: {
      type: String,
      default: ''
    },
    time: {
      type: Number,
      default: 0
    },
    addrInfo: {
      type: Object,
      default() {

      }
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang='scss' scoped>
@mixin set-bg-left($bg1, $bg2, $bg3) {
  background: $bg1; /*一些不支持背景渐变的浏览器*/
  background: linear-gradient($bg2, $bg3);
  background: -moz-linear-gradient(left, $bg2, $bg3);
  background: -o-linear-gradient(left, $bg2, $bg3);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from($bg2),
    to($bg3)
  );
  background: -webkit-linear-gradient(left, $bg2, $bg3);
}
.order-state {
  width: 100%;
  color: #fff;
  height: 100px;
  padding: 15px 15px;
  font-size: 16px;
  box-sizing: border-box;
  @include set-bg-left(#ff98a0, #ffb5bb, #ffa6ad);

  span {
    font-size: 16px;
    position: relative;
    top: 1px;
  }
}

.address {
  background: #fff;
  padding: 15px 10px;
  border-radius: 6px;
  margin: -40px 15px 15px;
  width: calc(100% - 50px);
  z-index: 12;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 10px #f2f2f2;
  .icon-point {
    width: 33px;
    height: 33px;
    text-align: center;
    border-radius: 50%;
    background-color: #ffb5bb;
    .icon-dw {
      font-size: 20px;
      color: #fff;
      line-height: 33px;
      &:before {
        content: '\e90c';
      }
    }
  }

  .addr-cont {
    width: calc(100% - 30px);
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;

    p {
      font-size: 12px;
      color: #666;

      &:nth-child(1) {
        color: #333;

        span {
          &:nth-child(2) {
            float: right;
          }
        }
      }
    }
  }
}
</style>
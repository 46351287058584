<!--  -->
<template>
  <div class="pwd_box">
    <img @click="handleClick(0)" class="close_icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210909161721976.jpg" alt="">
    <div class="title1">请输入支付密码</div>
    <div class="title2">合计:￥<span class="title2_money">{{payAmount}}</span></div>
    <div class="title3">账户余额抵扣:￥{{accountBalance}} 采购券抵扣￥{{mixedBalance}}</div>
    <input v-model="password" class="pwd_input" type="password" placeholder="点击输入密码">
    <div class="title4">如未设置密码或丢失密码，请点击<span class="safety_center" @click="jumpPage">安全中心</span>，修改密码
    </div>
    <div class="button_box">
      <div @click="handleClick(0)" class="button cancel">取消</div>
      <div @click="handleClick(1)" class="button confirm">确定</div>
    </div>
  </div>
</template>

<script>
const md5 = require('md5');
export default {
  name: '',
  props: {
    accountBalance: {
      type: Number,
      default: 0
    },
    mixedBalance: {
      type: Number,
      default: 0
    },
    payAmount: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: '',
      secretkey: '',
      payErrorMsg: ''
    }
  },
  created() {
    this.getSecretKey();
  },
  methods: {
    handleClick(type) {
      if (type === 0) return this.$emit("handletap", { type });
      let pwd = this.password;
      if (!pwd) return this.$toast("请输入支付密码!");
      if (!this.secretkey) {
        if (this.payErrorMsg) return this.$dialog.alert({ title: '提示', message: this.payErrorMsg }).then(() => {
          window.location.href = this.baseHost + "/7999/Arongleweb/html/mycenter/account.html";
        });
        this.$dialog.alert({ title: '提示', message: '支付错误,请联系客服!' });
      }
      this.$emit("handletap", { type, password: md5(pwd + this.secretkey).toUpperCase() });
    },
    getSecretKey() {
      this.get("/OrderGoods/Pay/GetSecretkey", {}, 2).then(res => {
        if (res.code === 1) {
          let { secretkey } = res.response;
          this.secretkey = secretkey;
        } else {
          this.payErrorMsg = res.msg;
        }
      })
    },
    jumpPage() {
      window.location.href = `${this.baseHost}/7999/Arongleweb/html/mycenter/account.html`
    }
  },
  watch: {
    show(newVal) {
      if (newVal) this.password = "";
    }
  }
}
</script>
<style lang='scss' scoped>
.pwd_box {
  width: 300px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  .close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
  .title1 {
    font-size: 16px;
  }
  .title2 {
    font-size: 10px;
    margin-top: 5px;
    .title2_money {
      font-size: 20px;
    }
  }
  .title3 {
    margin-top: 5px;
  }
  .title4 {
    margin-top: 8px;
    max-width: 87%;
    text-align: center;
    .safety_center {
      color: #ff98a0;
      display: inline-block;
      border-bottom: 1px solid #ff98a0;
    }
  }
  .pwd_input {
    margin-top: 8px;
    border: none;
    text-align: center;
    background-color: #f8f8f8;
    width: 265px;
    height: 44px;
    font-size: 14px;
  }
  .button_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    margin-top: 8px;
    .button {
      width: 120px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-radius: 500px;
    }
    .cancel {
      border: 1px solid #e5e5e5;
      color: #666666;
    }
    .confirm {
      background-color: #ff98a0;
      color: #fff;
    }
  }
}
</style>
let wx = require('weixin-js-sdk');
const initWX = data => {
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timeStamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.paySign,// 必填，签名
        jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表s
    });
}
const wxPay = (data) => {
    initWX(data);
    return new Promise((resolve, reject) => {
        wx.ready(() => {
            wx.chooseWXPay({
                appId: data.appId,
                timestamp: data.timeStamp,
                nonceStr: data.nonceStr,
                package: data.package,
                signType: data.signType,
                paySign: data.paySign,
                success: (res) => {
                    if (res.errMsg === "chooseWXPay:ok") {
                        resolve('支付成功');
                    } else if (res.errMsg === "get_brand_wcpay_request:cancel") {
                        reject('支付已取消');
                    } else reject('支付失败');
                },
                fail: () => {
                    reject("支付失败");
                },
                cancel: () => {
                    reject("支付已取消");
                },
            });
        })
        wx.error(function (res) {
            reject(res);
            console.log('err', res)
        });
    })
}

export {
    wxPay
}
<template>
  <div v-if="orderDetail">
    <div class="detail-content">
      <div class="detail-cont">
        <head-public :state="orderDetail.state" :state-txt="orderDetail.stateTxt" :time="orderDetail.overdueTimes" :addr-info="orderDetail.addrInfo" />
        <div>

        </div>
        <div class="pro-first">
          <div class="left-spe" v-if="orderDetail.BrandName">
            <img :src="orderDetail.brandId === 4 ? imageMW : (orderDetail.BrandId === 3 ? imageFJS : imageXM)" alt="" />
            <span>{{ orderDetail.BrandName }}</span>
          </div>
        </div>
        <div class="prod-cont prod-cont-style" v-for="(item, index) in productList" :key="item.type">
          <template v-if="item.count > 0">
            <div class="list-tit">
              <span class="tit-left">{{ item.title }}</span>
              <span class="tit-right">共计: {{ item.count }}件</span>
            </div>
            <div class="hint" v-if="item.type === 1">特惠专区加购商品将由系统自动为您存入蔓薇仓库，可存储180天，到期自动为您发货！</div>

            <div class="prod-list" v-for="(product, index) in item.data" :key="index">
              <img v-lazy="product.goodsImg">
              <div class="prod-desc">
                <p class="ellipsis1 pro-name">{{ product.goodsName }}</p>
                <p class="ellipsis2 pro-spe">{{ product.goodsSpecifications }}</p>
                <!-- <p class="pro-price" v-if="product.isShowPrice"></p> -->
                <p class="pro-price" v-if="product.isShowPrice">￥<span>{{ product.goodsPrice }}</span>
                  <van-tag type="primary" round color="#ff98a0">加购价</van-tag>
                  <span class="original-price">官方价：￥{{ product.originalPrice }}</span>
                </p>
              </div>
              <span class="prod-num">x{{ product.goodsCount }}</span>
            </div>
          </template>
        </div>
        <div class="prod-cont prod-cont-style" v-if="orderDetail.waybillList && orderDetail.waybillList.length > 0">
          <div class="prod-item">
            <div class="list-tit">
              <span class="tit-left">物流信息</span>
            </div>
            <div class="deliy-cont">
              <div class="logistics-cont">
                <span class="title">快递单号：</span>
                <div class="nums-list">
                  <template v-for="(de, idx) in orderDetail.waybillList">
                    <div class="num-item">{{ de.expressNum }}
                      <a class="text-link" :href="'http://m.kuaidi100.com/result.jsp?nu=' + de.expressNum">查看物流
                        <van-icon name="arrow" />
                      </a>
                    </div>
                  </template>
                </div>
              </div>
              <!-- <van-button type="default" color="#EEEEEE" @click="viewDeliverMore()">查看更多
                <img src="../../../assets/images/arrow-right.png" />
              </van-button> -->
            </div>
          </div>
        </div>
        <div class="order-detail">
          <van-tabs v-model="active" color="#FFB5BB" title-active-color="#FFB5BB" background="transparent">
            <van-tab title="订单明细"></van-tab>
          </van-tabs>
          <div class="or-desc">
            <van-cell title="订单编号：" :border="false">
              {{ orderDetail.orderNum }}
              <span v-if="orderDetail.BrandName">({{ orderDetail.BrandName }})</span>
            </van-cell>
            <van-cell title="备注信息：" v-if="orderDetail.remark" :value="orderDetail.remark" :border="false" />
            <van-cell title="支付方式：" v-if="orderDetail.payTypeText" :value="orderDetail.payTypeText" :border="false" />
            <van-cell title="下单时间：" v-if="orderDetail.createTimes" :value="orderDetail.createTimes" :border="false" />
            <van-cell title="付款时间：" v-if="orderDetail.payTime" :value="orderDetail.payTime" :border="false" />
            <van-cell title="取消原因：" v-if="orderDetail.cancelReasons" :value="orderDetail.cancelReasons" :border="false" />
          </div>
          <div class="or-desc price-right">
            <van-cell title="商品总额：" v-if="orderDetail.totalAmount" :value="'￥' + orderDetail.totalAmount" :border="false" />
            <van-cell title="已优惠：" v-if="orderDetail.discountAmount" :value="'-￥' + orderDetail.discountAmount" :border="false" />
            <van-cell title="邮费：" v-if="orderDetail.postage" :value="'￥' + orderDetail.postage" :border="false" />
            <van-cell title="预存红包抵扣：" v-if="orderDetail.state != 0 && orderDetail.redBalance" :value="'-￥' + orderDetail.redBalance" :border="false" />
            <van-cell :title="(orderDetail.brandId === 4 ? '大健康' : '美妆') + '账户余额抵扣：'" v-if="orderDetail.payAccountBalance" :value="'-￥' + orderDetail.payAccountBalance" :border="false" />
            <van-cell title="采购券抵扣：" v-if="orderDetail.payMixedAmount" :value="'-￥' + orderDetail.payMixedAmount" :border="false" />
            <van-cell title="加价购奖励：" v-if="orderDetail.addRebateAmount" :value="'￥' + orderDetail.addRebateAmount" :border="false" />
            <template v-if="orderDetail.state === 0">
              <van-divider v-if="orderDetail.state != 99" />
              <van-cell title="预存红包抵扣：" v-if="orderDetail.redBalance" :value="'-￥' + orderDetail.redBalance" :border="false" value-class="font-pink" />
              <van-cell title="采购券抵扣：" :value="`共￥${orderDetail.mixedAmount}可用`" :border="false" value-class="font-pink" />
              <van-cell :title="(orderDetail.brandId == 4 ? '大健康' : '美妆') + '账户余额抵扣：'" :value="`共￥${orderDetail.accountBalance}可用`" :border="false" value-class="font-pink" />
              <van-divider />
              <van-cell title="支付方式：" :border="false" value-class="pay-check-list">
                <van-radio-group v-model="payType" direction="horizontal" checked-color="#FF98A0">
                  <van-radio :name="1" :disabled="orderDetail.disAccount">采购券金额</van-radio>
                  <van-radio :name="2" :disabled="orderDetail.disAccount">账户余额</van-radio>
                  <van-radio :name="0">都不使用</van-radio>
                </van-radio-group>
              </van-cell>
            </template>
          </div>
        </div>
      </div>
      <div class="footer" v-if="orderDetail">
        <div class="last-line">
          <div class="pay-money">{{ orderDetail.payResultTxt }}：<span class="unit">￥</span><span class="money">{{
            orderDetail.payAmount }}</span>
          </div>
          <div>
            <template v-for="(it, idx) in orderDetail.btnList">
              <button :class="it.className" :key="idx" type="button" @click="handleBtnClick(it)" v-if="it.showType === 1">
                {{ it.text }}
              </button>
            </template>
          </div>
        </div>

      </div>
      <van-popup v-model="showCancelLayer">
        <Cancel @cancel="confirmCancel" />
      </van-popup>
    </div>
    <toolbar />
    <van-popup v-model="isPayByPwd" round>
      <password :show="isPayByPwd" :payAmount="orderDetail.payAmount" :accountBalance="orderDetail.accountBalance" :mixedBalance="orderDetail.mixedAmount" @handletap="hanldePwdClick" />
    </van-popup>
  </div>
</template>

<script>
import HeadPublic from "../HeadPublic.vue"
import Cancel from "../Cancel";
import { wxPay } from "../../../utils/wxPay";
import toolbar from "../../toolbar";
import password from "../Password.vue"
import brandFjs from "../../../assets/images/brand-3.png";
import brandXk from "../../../assets/images/brand-1.png";
import brandMw from "../../../assets/images/brand-4.png"
export default {
  name: "ExchangeOrderDetail",
  components: { toolbar, password, Cancel, HeadPublic },
  props: {
    orderId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      active: 0,
      orderDetail: null,
      isPayByPwd: false,
      payType: 0,
      imageFJS: brandFjs,
      imageXM: brandXk,
      imageMW: brandMw,
      showCancelLayer: false,
      productList: [],
      payMayObj: null
    }
  },
  created() {
    this.getOrderDetail();
    this.getWXShow()

  },
  methods: {
    getWXShow() {
      this.get('/Base/Pay/GetPayWayIsOpen', "", 2).then(res => {
        if (res.code === 1) {
          let obj = res.response;
          this.payMayObj = obj;
        }
      })
    },
    getOrderDetail() {
      this.$toast.loading({ message: '加载中...', overlay: true, duration: 0 });
      this.get("/OrderGoods/PreferentialZone/GetPreferentialZoneOrderDetails", { orderId: this.orderId }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let totalNum = 0, data = res.response;
          data.goodsList.forEach(it => {
            totalNum += it.goodsCount
          });
          data.payTypeText = (() => {
            if ([99, 0].indexOf(data.state) >= 0) return '';
            if (data.payType === 1) return '在线支付(微信支付)';
            if (data.payType === 2) return '在线支付(支付宝支付)';
            // if (data.payType === 3) return '在线支付(账户余额)';
            // if (data.payType === 4) return '在线支付(预存红包)';
            return '在线支付(账户余额)';
          })();
          data.addrInfo = {
            name: data.name,
            phone: data.phone,
            fullAddress: data.fullAddress
          }
          this.productList = this.calculateList(data.goodsList, data.brandId);
          data.payResultTxt = data.state === 0 ? '待付款' : '实付款';
          data.btnList = this.initBtnList(data.state);
          data.totalNum = totalNum;
          data.BrandName = data.brandId == 4 ? '大健康' : '美妆';
          if (data.mixedAmount >= data.payAmount) this.payType = 1;
          else if (data.accountBalance >= data.payAmount) this.payType = 2;
          else if (data.mixedAmount + data.accountBalance > data.payAmount) this.payType = 1;
          else data.disAccount = true;
          this.orderDetail = data;
        } else {
          this.$toast(res.msg);
        }
      })
    },
    initBtnList(state) {
      let arr = [];
      if ([0, 10].indexOf(state) >= 0) arr.push(
        { text: '取消订单', type: 1, className: 'col-gray', showType: 1 }
      );
      if (state === 0) arr.push({ text: '去付款', type: 2, className: '', showType: 1 });
      else if (state === 20) arr.push({ text: '待发货', type: 1, className: '', showType: 2 });
      else if (state === 30) arr.push({ text: '已发货', type: 1, className: '', showType: 2 });
      else if (state === 99) arr.push({ text: '已取消', type: 1, className: '', showType: 2 });
      else if (state === 100) arr.push({ text: '已完成', type: 1, className: '', showType: 2 });
      return arr;
    },
    handleBtnClick(item) {
      //取消订单
      if (item.type === 1) {
        this.$dialog.confirm({ title: '提示', message: '是否确认取消该订单' }).then(() => {
          this.showCancelLayer = true;
        }).catch(() => {
        });
      } else if (item.type === 2) {
        if (this.orderDetail.state == 0 && (this.orderDetail.overdueTimes - Date.now() > 0)) {
          this.$dialog.confirm({ title: '提示', message: '是否确认支付该订单' }).then(() => {
            this.confirmPay();
          }).catch(() => {
          });
        } else {
          this.$toast('该订单已超时')
        }

      }
    },
    //确认取消订单
    confirmCancel(e) {
      let { type, message } = e;
      this.showCancelLayer = false;
      if (type === 1) return
      this.$toast.loading({ message: '订单取消中...', overlay: true, duration: 0 })
      let params = {
        orderId: this.orderId,
        reasons: message,
        v: 1,
        operationUserId: 0
      }
      this.post('OrderGoods/PreferentialZone/CancelOrder', params, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.$toast("取消成功!");
          this.orderDetail.state = 99;
          this.orderDetail.stateTxt = "已取消";
          this.orderDetail.btnList = this.initBtnList(99);
          this.orderDetail.cancelReasons = message;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //确认支付
    confirmPay() {
      let type = this.payType;
      this.$toast.loading({ message: '支付中...', overlay: true, duration: 0 })
      if (type === 0) return this.wxPay();
      this.isPayByPwd = true;
    },
    hanldePwdClick({ type, password }) {
      this.isPayByPwd = false;
      if (type === 0) return this.$toast.clear();;
      this.accountPay(password);
    },
    //账户余额或者采购券支付
    accountPay(password) {
      this.post("/OrderGoods/PreferentialZone/PayAccountBalanceOrder", {
        orderId: this.orderId,
        depayType: this.payType === 1 ? 1 : 0,
        password: password
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.orderDetail.state = 10;
          this.orderDetail.stateTxt = "待发货";
          this.orderDetail.btnList = this.initBtnList(10);
          this.$router.push("/special/success/" + this.orderId + "?orderNum=" + this.orderDetail.orderNum)
        } else {
          this.$dialog.alert({ title: '错误', message: res.msg })
        }
      })
    },
    //微信支付
    wxPay() {
      let params = {
        orderId: this.orderId,
        payType: this.payMayObj && this.payMayObj.yeePay ? 3 : 1,
        v: 1
      }
      this.post('/OrderGoods/PreferentialZone/PrePayPreferentialZoneOrder', params, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response.wechat;
          wxPay({
            appId: data.appId || data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
            paySign: data.paySign || data.sign, signType: data.signType, package: data.package
          }).then(() => {
            // this.$toast("支付成功");
            this.orderDetail.state = 10;
            this.orderDetail.stateTxt = "已完成";
            this.orderDetail.btnList = this.initBtnList(10);
            this.$router.push("/special/success/" + this.orderId + "?orderNum=" + this.orderDetail.orderNum)
          }).catch(msg => {
            this.$toast(msg);
          })
        } else {
          this.$dialog.alert({ title: '错误', message: res.msg })
        }
      })
    },
    calculateList: function (list, brandId) {
      // type: 0 兼容之前的订单  1  备存仓库 2 自用发货
      // deliveryMethod   发货方式 1国内发货 2澳洲发货
      let arr = [
        { title: '中国产地(国内发、自用发货)', data: [], datalist: [], type: 3, count: 0 },
        { title: '', data: [], datalist: [], type: 0, count: 0 },
        { title: '澳洲产地(备存仓库)', data: [], datalist: [], type: 1, count: 0 },
        { title: '澳洲产地(自用发货)', data: [], datalist: [], type: 2, count: 0 },
      ];
      for (let i = 0; i < list.length; i++) {
        // PickUpType 1:代理自用2:非自用
        if (list[i].deliveryMethod === 1 && brandId === 4) {
          arr[0].data.push(list[i])
        } else if (list[i].deliveryMethod === 2 && brandId === 4) {
          arr[2].data.push(list[i])
        } else {
          arr[1].data.push(list[i])
        }
      }
      arr.forEach(item => {
        let count = 0;
        item.data.forEach(it => {
          count += it.goodsCount;
        })
        item.count = count
      })
      console.log(arr);
      return arr;
    },
    //查看更多物流信息
    viewDeliverMore: function () {
      window.location.href = `/#/order/logistics?orderId=${this.orderId}`
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  overflow-y: auto;
  background-color: #f8f8f8;

  .detail-cont {
    overflow-y: scroll;
    padding-bottom: 118px;

    .prod-cont-style {
      margin-bottom: 15px;
    }

    .prod-cont {
      .list-tit {
        margin: 10px 15px 1px;
        padding: 10px 15px;
        background-color: #fff5f6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tit-left {
          font-size: 12px;
          color: #666666;
        }

        .tit-right {
          font-size: 12px;
          color: #666666;

          span {
            color: #ff98a0;
          }
        }
      }

      .hint {
        color: #ff98a0;
        font-size: 12px;
        padding: 8px 15px;
        margin: 0 15px;
        border-top: 1px solid #ffffff;
        background-color: #fff5f6;
      }
    }

    .prod-list,
    .spell-list {
      display: flex;
      align-items: center;
      font-size: 0;
      position: relative;
      margin: 0 15px;
      padding: 10px 15px;
      width: calc(100% - 60px);
      background-color: #fff;

      img {
        width: 80px;
        height: 80px;
      }

      .prod-desc {
        width: calc(100% - 95px);
        display: inline-block;
        vertical-align: top;
        padding: 0 0 0 15px;

        .pro-name {
          font-size: 14px;
          color: #636363;
        }

        .pro-spe {
          font-size: 12px;
          color: #636363;
        }

        .pro-val {
          font-size: 12px;
          color: #999999;
        }

        .pro-price {
          color: #ff98a0;
          font-size: 14px;
          padding-top: 15px;

          span {
            font-size: 18px;
          }

          .van-tag {
            padding: 2px 4px;
            font-size: 12px;
            display: inline-block;
            transform: scale(0.75);
          }

          .original-price {
            color: #999999;
            font-size: 10px;
            text-decoration: line-through;
          }

          .count {
            color: #999;
            float: right;
          }
        }
      }

      .prod-num {
        font-size: 14px;
        color: #b5b5b5;
        position: absolute;
        right: 15px;
        bottom: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30px;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.04)
        );
        background: -webkit-linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.04)
        );
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
        border-bottom: none;

        &:after {
          height: 0;
        }
      }
    }

    .pro-first,
    .spell-first {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      margin: 0 15px 1px;
      padding: 0 15px;
      width: calc(100% - 60px);
      background-color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .left-spe {
        display: flex;
        align-items: center;

        img {
          width: 30px;
        }

        span {
          font-size: 12px;
          color: #666666;
        }
      }

      .right-spe {
        span {
          font-size: 12px;

          &:nth-child(1) {
            color: #999999;
          }

          &:nth-child(2) {
            color: #ff98a0;
          }
        }
      }
    }

    .pro-first {
      .left-spe {
        img {
          width: 45px;
        }
      }

      & + .prod-cont {
        .list-tit {
          margin-top: 0;
        }
      }
    }

    .text-right {
      text-align: right;
    }
  }

  .prod-item {
    .list-tit {
      margin-top: 0;
    }

    .deliy-cont {
      margin: 0 15px;
      padding-bottom: 11px;
      text-align: center;
      background-color: #fff;

      .logistics-cont {
        display: flex;
        text-align: left;
        padding: 11px 15px;
        align-items: flex-start;

        .title {
          width: 100px;
        }

        .nums-list {
          flex: 1;

          .num-item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            justify-content: space-between;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .text-link {
            color: #ff98a0;
          }
        }
      }

      .van-button {
        height: 26px;
        width: 70%;
        border-radius: 3px;
        line-height: 24px;

        .van-button__text {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          width: 11px;
          margin-left: 5px;
        }

        &:active {
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  .order-detail {
    color: #666;
    width: calc(100% - 30px);
    margin: 0 15px;

    .or-desc {
      background: #fff;
      padding: 15px 10px;
      margin-top: 10px;

      .van-cell {
        padding: 3px 0;

        .van-cell__title {
          flex: 1;
          color: #999;
          font-size: 12px;
        }

        .van-cell__value {
          flex: 2;
          color: #666666;
          font-size: 12px;
          text-align: left;
        }

        .font-pink {
          color: #ffb5bb;
        }
      }

      .van-divider {
        margin: 10px 0;
      }

      &.price-right {
        .van-cell {
          .van-cell__value {
            text-align: right;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .income {
      text-align: right;
      font-size: 12px;
      color: #333;
      padding: 5px 15px 15px;
      background: #fff;

      span {
        font-weight: 600;
      }
    }

    .approval-process {
      .van-steps {
        .van-step__title {
          line-height: 20px;
        }

        .set-round {
          .van-step__circle {
            background-color: #ff98a0 !important;
          }
        }

        .first-active {
          .van-icon-checked {
            color: #ff98a0 !important;
          }

          .van-step__title {
            color: #ff98a0 !important;
          }
        }

        .solid-line {
          .van-step__line {
            border-left: 1px solid #ccc;
            top: 16px;
          }
        }

        .cope-list {
          font-size: 12px;
          color: #333;
          padding-top: 10px;
          border-top: 1px dashed #f1f1f1;
          line-height: 20px;

          span {
            float: right;
          }
        }
      }
    }

    .coupon-detail {
      color: #999;
      padding: 10px;
      position: relative;
      border-radius: 3px;
      margin-bottom: 10px;
      border: 1px solid #ffb5bb;
      background-color: #fff9f9;

      i {
        color: #ff98a0;
      }

      &:before {
        content: '';
        position: absolute;
        top: -10px;
        right: 10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ffb5bb;
      }

      &:after {
        content: '';
        position: absolute;
        top: -9px;
        right: 11px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pay-check-list {
    flex: 1.5;

    .van-radio-group {
      justify-content: space-between;

      .van-radio {
        margin-right: 0;
        margin-bottom: 6px;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  background: #fff;
  border-top: 1px solid #f4f4f4;
  z-index: 12;

  p {
    font-size: 12px;
    color: #333;

    span {
      color: #ff98a0;

      &.big {
        font-size: 16px;
      }
    }
  }

  .foot-btn {
    position: absolute;
    right: 15px;
    top: 8px;
    left: 120px;
    text-align: right;

    button {
      font-size: 14px;
      color: #333;
      border: 1px solid #333;
      padding: 3px 16px;
      border-radius: 30px;
      background: #fff;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      &.col {
        border-color: #ff98a0;
        color: #ff98a0;
      }
    }

    .prompt {
      width: calc(100% - 100px);
      color: #929292;
      font-size: 11px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      text-overflow: ellipsis;
      line-height: 31px;
    }
  }

  .spell-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 44px;

    .left-spell {
      height: 100%;
      display: flex;
      align-items: center;

      .img-con {
        width: 28px;
        height: 28px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        z-index: 2;

        img {
          width: 100%;
        }

        span {
          box-sizing: border-box;
          display: inline-block;
          width: 100%;
          height: 100%;
          line-height: 26px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          background-color: #f8f8f8;
          font-size: 18px;
          color: #cccccc;
          text-align: center;
        }

        &.right-con {
          z-index: 1;
          margin-left: -12px;

          span {
            border: dashed 1px #d9d9d9;
          }
        }
      }

      .remain-person {
        font-size: 12px;
        color: #666;

        > span {
          color: #ff98a0;
        }
      }

      .complete {
        font-size: 12px;
        color: #666666;

        span {
          color: #999999;
        }
      }
    }

    .invite-spell {
      border: solid 1px #ff98a0;
      color: #ff98a0;
      font-size: 12px;
      background-color: #fff;
      height: 26px;
      line-height: 26px;
      padding: 0 4%;
      -webkit-border-radius: 13px;
      -moz-border-radius: 13px;
      border-radius: 13px;
    }
  }

  .last-line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pay-money {
      font-size: 12px;
      color: #666666;

      .unit {
        font-size: 14px;
        color: #ff98a0;
      }

      .money {
        font-size: 18px;
        color: #ff98a0;
      }

      .money-remark {
        font-size: 12px;
        color: #ff98a0;
      }
    }

    button {
      //flex-shrink: 0;
      border: solid 1px #ff98a0;
      color: #ff98a0;
      font-size: 12px;
      background-color: #fff;
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      margin-right: 10px;
      border-radius: 30px;

      &:last-child {
        margin-right: 0;
      }

      &.col-gray {
        color: #999;
        border-color: #999;
      }
    }
  }
}
</style>
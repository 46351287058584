<template>
  <div class="detail-content">
    <div class="detail-cont" v-if="orderDetail">
      <div class="order-state">

        <div style="display: flex;align-items: center">
          <span class="icon-sl" style="margin-right: 5px"></span>
          {{orderDetail.stateTest}}
          <div v-if="orderDetail.state === 0 && (orderDetail.overdueTimes - Date.now() > 0)" style="display: flex;align-items: flex-start;color: #fff;font-size: 10px;margin-left: 15px;position: relative;top: 3px">
            <van-count-down style="color:#fff;font-size: 10px;position: relative;bottom: 1px" :time="orderDetail.overdueTimes - Date.now()" />
            后自动取消
          </div>
        </div>
        <template v-if="orderDetail.AuditingLevelName2 && orderType===2">
          （{{orderDetail.AuditingLevelName2}}）
        </template>
      </div>
      <div class="address">
        <span class="icon-dw"></span>

        <div class="addr-cont">
          <p><span>收货人：{{orderDetail.OrderUserName}}</span><span>{{orderDetail.OrderUserPho}}</span>
          </p>
          <p>收货地址：{{orderDetail.OrderAddress}}</p>
        </div>
      </div>
      <div class="prod-cont prod-cont-style">
        <!-- && orderDetail.brandId === 4 -->
        <div v-if="orderType === 12">
          <div class="list-tit">
            <span class="tit-left">备存仓库</span>
            <span class="tit-right">共计: {{orderDetail.totalNum}}件</span>
          </div>
          <div class="hint">{{ orderDetail.orderStoreText }}</div>
        </div>
        <div class="pro-first" v-if="[10,12].indexOf(orderType) >= 0">
          <div class="left-spe" v-if="orderType  === 10"><span>兑换商品</span></div>
          <div class="left-spe" v-if="orderDetail.BrandName">
            <img :src="orderDetail.BrandId === 4 ? imageMW : (orderDetail.BrandId === 3 ? imageFJS : imageXM)" alt="" />
            <span>{{orderDetail.BrandName}}</span>
          </div>
          <div class="right-spe" v-if="orderDetail.ProductValue">
            <span>总产品参数值为：</span><span>{{orderDetail.ProductValue}}</span>
          </div>
        </div>
        <div class="prod-list" v-for="(product,index) in orderDetail.ProductList" :key="index">
          <img v-lazy="product.ImageUrl">
          <div class="prod-desc">
            <p class="ellipsis1 pro-name">{{product.GoodsName}}</p>
            <p class="ellipsis1 pro-spe">{{product.product_content}}</p>
            <p class="pro-val">{{product.Specifications}}</p>
            <p class="pro-price">￥<span>{{product.Amount}}</span>
              <template v-if="orderType === 12">
                <van-tag type="primary" round color="#ff98a0">加购价</van-tag>
                <span class="original-price">官方价：￥{{ product.originalPrice }}</span>
              </template>
            </p>
          </div>
          <span class="prod-num">x{{product.Count}}</span>
        </div>
      </div>
      <div class="order-detail">
        <div class="or-desc">
          <van-cell title="订单编号：" :border="false">
            {{orderDetail.OrderNum}}
            <span v-if="orderDetail.BrandName">({{orderDetail.BrandName}})</span>
          </van-cell>
          <van-cell title="备注信息：" v-if="orderDetail.SellerMessage" :value="orderDetail.SellerMessage" :border="false" />
          <template v-if="[1,2].indexOf(orderType) >= 0">
            <van-cell title="送单人姓名：" :value="orderDetail.SendOrderUserName" :border="false" />
            <van-cell title="电话号码：" :value="orderDetail.SendOrderUserPhone" :border="false" />
            <van-cell title="店铺：" :value="orderDetail.SendOrderLevelName" :border="false" />
            <!-- <van-cell title="授权编码：" :value="orderDetail.SendOrderAuthoryCode" :border="false" /> -->
            <van-cell title="收货地址：" :value="orderDetail.OrderAddress" :border="false" />
            <van-cell title="邀请人：" :value="orderDetail.OrderPushName" :border="false" />
            <van-cell title="邀请人手机号：" :value="orderDetail.OrderPushPho" :border="false" />
            <template v-if="orderDetail.waybillList && orderDetail.waybillList.length > 0">
              <van-cell :title="'快递单号：' + de.expressNum" :border="false" v-for="(de,idx) in orderDetail.waybillList" :key="idx">
                <a :href="'http://m.kuaidi100.com/result.jsp?nu='+de.expressNum" class="waybillList_a">查看物流
                  <van-icon name="arrow" />
                </a>
              </van-cell>
            </template>
          </template>
          <van-cell title="赠品信息：" v-if="orderDetail.Remark_List && orderDetail.Remark_List.length > 0" :border="false">
            <div class="gift-list-box">
              <div class="gift-item " v-for="(item,idx) in orderDetail.Remark_List" :key="idx">
                <span>{{item.name}}</span>
                <span class="align-right" v-if="item.details || (item.subPrizeList && item.subPrizeList.length > 0)" @click="giftDetailContent = item">
                  查看详情<i class="icon-thin-right"></i>
                </span>
              </div>
            </div>
          </van-cell>
          <van-cell title="礼包信息：" v-if="orderDetail.HasCouponSu" :value="'[新人礼包x' + orderDetail.HasCouponSu +']'" :border="false" />
          <van-cell title="使用代金券：" v-if="orderDetail.CouponAmount" :value="orderDetail.CouponAmount" :border="false" />
          <van-cell title="支付方式：" v-if="orderDetail.payTypeText" :value="orderDetail.payTypeText" :border="false" />
          <van-cell title="下单时间：" v-if="orderDetail.OrderDateTime" :value="orderDetail.OrderDateTime" :border="false" />
          <van-cell title="付款时间：" v-if="orderDetail.OrderPayTime" :value="orderDetail.OrderPayTime" :border="false" />
          <van-cell title="递交公司时间：" v-if="orderDetail.AreaAuditTime" :value="orderDetail.AreaAuditTime" :border="false" />
          <van-cell title="取消原因：" v-if="orderDetail.cancelReason" :value="orderDetail.cancelReason" :border="false" />
        </div>
        <div class="or-desc price-right">
          <van-cell title="总产品参数值：" v-if="orderDetail.ProductValue" :value="orderDetail.ProductValue" :border="false" />
          <van-cell :title="[12].indexOf(orderType)>=0 ? '商品总额：' : '合计金额：'" v-if="orderDetail.OrderTotalPrice" :value="'￥' + orderDetail.OrderTotalPrice" :border="false" />
          <van-cell title="已优惠：" v-if="orderDetail.discountAmount" :value="'-￥' + orderDetail.discountAmount" :border="false" />
          <van-cell title="邮费：" v-if="orderDetail.Postage" :value="'￥' + orderDetail.Postage" :border="false" />
          <van-cell title="余额抵扣：" v-if="orderDetail.balance" :value="'-￥' + orderDetail.balance" :border="false" />
          <van-cell v-if="orderDetail.orderRealPrice" :value="'订单总价：￥' + orderDetail.orderRealPrice" :border="false" />
          <van-divider v-if="[12].indexOf(orderType) >= 0  && orderDetail.state != 99" />
          <van-cell title="抵扣券：" v-if="orderDetail.CouponAmount" :value="orderDetail.CouponAmount" :border="false" />
          <van-cell title="订货券：" v-if="orderDetail.CouponTotal_5g > 0 " :value="orderDetail.CouponTotal_5g" :border="false" />
          <van-cell title="购兑换券金额：" v-if="orderDetail.zbs_AddMoney > 0 " :value="orderDetail.zbs_AddMoney" :border="false" />
          <van-cell title="预存红包抵扣：" v-if="orderDetail.Prestore " :value="'-￥' + orderDetail.Prestore" :border="false" />
          <van-cell title="兑换红包：" v-if="orderDetail.RedBagBalance " :value="orderDetail.RedBagBalance" :border="false" value-class="font-pink" />
          <template v-if="[12].indexOf(orderType) >= 0 && orderDetail.state === 0">
            <van-cell title="采购券抵扣：" :value="`共￥${orderDetail.mixedAmount}可用`" :border="false" value-class="font-pink" />
            <van-cell :title="(orderDetail.BrandId == 4 ? '大健康' :'美妆')+'账户余额抵扣：'" :value="`共￥${orderDetail.accountBalance}可用`" :border="false" value-class="font-pink" />
            <van-divider />
            <van-cell title="支付方式：" :border="false" value-class="pay-check-list">
              <van-radio-group v-model="checked" direction="horizontal" checked-color="#FF98A0">
                <van-radio :name="1">采购券金额</van-radio>
                <van-radio :name="2">账户余额</van-radio>
                <van-radio :name="0">都不使用</van-radio>
              </van-radio-group>
            </van-cell>
          </template>
        </div>
        <template v-if="orderType === 2 ">
          <div class="or-desc price-right">
            <van-cell title="下单人订单金额：" :value="orderDetail.OrderTotalPrice" :border="false" />
            <van-cell title="应收金额：" :value="orderDetail.OrderTotalPrice" :border="false">
              <b class="font-red">{{orderDetail['CouponAmount'] > 0 ? '(优惠券折扣)' : ''}}</b>
              <p>￥{{orderDetail.aInputPrice}}
                <i class="gray" v-if="orderDetail.childCouponMoneyStr">
                  ({{orderDetail.ChildCouponDetail.CurrentPrice.CouponMoney}}{{
                                    orderDetail.childCouponMoneyStr}})
                </i>
              </p>
              <div style="font-size: 10px;color: #f44;" v-if="orderDetail.Prestore > 0">
                （线下支付订单，公司审核后会将差价收益与预存红包抵扣金额一并返还您的虚拟账户）
              </div>
            </van-cell>
            <van-cell class="coupon-detail" v-if="getCCouponDetail" :border="false">
              <div v-html="getCCouponDetail"></div>
            </van-cell>
            <van-cell title="应付金额：" :border="false">
              <b class="font-red">{{orderDetail['CouponAmount'] > 0 ? '(优惠券折扣)' : ''}}</b>
              <p><i class="font-red">￥{{orderDetail.aOutputPrice}}</i>
                <i class="gray" v-if="orderDetail.apportionStr">({{orderDetail.apportionStr}})</i>
              </p>
            </van-cell>
          </div>
          <div class="income">预计差价收入 <b class="font-red">{{orderDetail['CouponAmount'] > 0 ? '(优惠券折扣)' : ''}}</b>：
            <span>￥{{orderDetail.aIncomePrice}}</span>
          </div>
        </template>
        <!--争霸赛活动-->
        <div class="or-desc price-right" v-if="orderAllInfo && orderAllInfo.joinInfo && orderAllInfo.joinInfo.exchangeAmount > 0">
          <van-cell title="兑换券兑换商品金额：" :value="'￥' + orderAllInfo.joinInfo.exchangeProAmount" :border="false" />
          <van-cell title="兑换券：" :value="'-￥' + orderAllInfo.joinInfo.exchangeAmount" :border="false" />
          <van-cell title=" 补差金额：" :value="'￥' + orderAllInfo.joinInfo.exchangeOrderAmount" :border="false" />
        </div>
      </div>
    </div>
    <div class="footer" v-if="orderDetail">
      <div class="last-line">
        <div class="pay-money">{{orderDetail.payResultTxt}}：<span class="unit">￥</span><span class="money">{{orderDetail.orderRealPrice}}</span>
        </div>
        <div>
          <template v-for="(it,idx) in orderDetail.btnList">
            <button :class="it.className" :key="idx" type="button" @click="handleBtnClick(it)" v-if="it.showType === 1">
              {{ it.text }}
            </button>
          </template>
        </div>
      </div>

    </div>
    <div class="gift-layer" v-if="giftDetailContent">
      <div class="sub-title">
        <div class="gift-theme" v-if="giftDetailContent.subPrizeList"><span>{{giftDetailContent.name}}</span>
        </div>
        <span class="icon-delete" @click="giftDetailContent = null"></span>
      </div>
      <div class="gift-content">
        <img :src="giftDetailContent.details" v-if="giftDetailContent.details">
        <div v-else class="g-list">
          <div class="g-item" v-for="(item,index) in giftDetailContent.subPrizeList" :key="index">
            <div class="g-name">{{index + 1}}) {{item.prizeName}}x{{item.count}};</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showCancelLayer">
      <Cancel @cancel="confirmCancel" />
    </van-popup>
  </div>
</template>

<script>
import Cancel from "./Cancel";
import brandFjs from "../../assets/images/brand-3.png";
import brandXk from "../../assets/images/brand-1.png";
import brandMw from "../../assets/images/brand-4.png"
export default {
  name: "OrderDetail",
  props: {
    orderDetail: {
      type: Object,
      default() {
        return null
      }
    },
    orderType: {
      type: Number,
      default: 0
    },
    store: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: { Cancel },
  data() {
    return {
      productList: [],
      orderData: null,
      orderAllInfo: null,
      isShowPayType: false,
      isCancel: false,
      giftDetailContent: null,
      showCancelLayer: false,
      imageFJS: brandFjs,
      imageXM: brandXk,
      imageMW: brandMw,
      checked: 0,
    }
  },
  created() {
  },
  methods: {
    handleBtnClick(item) {
      console.log(item.type)
      //取消订单
      if (item.type === 1) {
        this.$dialog.confirm({ title: '提示', message: '是否确认取消该订单' }).then(() => {
          this.showCancelLayer = true;
        }).catch(() => {
        });
      } else if (item.type === 2) {
        if (this.orderDetail.state == 0 && (this.orderDetail.overdueTimes - Date.now() > 0)) {
          this.$dialog.confirm({ title: '提示', message: '是否确认支付该订单' }).then(() => {
            this.$emit("pay", this.checked);
          }).catch(() => {
          });
        } else {
          this.$toast('该订单已超时')
        }

      }
    },
    confirmCancel(e) {
      this.showCancelLayer = false;
      if (e.type === 1) return;
      this.$emit("cancel", e);
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin set-bg-left($bg1, $bg2, $bg3) {
  background: $bg1; /*一些不支持背景渐变的浏览器*/
  background: linear-gradient($bg2, $bg3);
  background: -moz-linear-gradient(left, $bg2, $bg3);
  background: -o-linear-gradient(left, $bg2, $bg3);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from($bg2),
    to($bg3)
  );
  background: -webkit-linear-gradient(left, $bg2, $bg3);
}

@mixin set-gradient-bg($bg1, $bg2, $bg3) {
  background: $bg1; /*一些不支持背景渐变的浏览器*/
  background: linear-gradient(180deg, $bg2, $bg3);
  background: -moz-linear-gradient(top bottom, circle cover, $bg2, $bg3);
  background: -o-linear-gradient(top, $bg2, $bg3);
  background: -webkit-gradient(top, from($bg2), to($bg3));
  background: -webkit-linear-gradient(top, $bg2, $bg3);
}

.detail-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  overflow-y: auto;
  background-color: #f8f8f8;

  .detail-cont {
    overflow-y: scroll;
    padding-bottom: 118px;

    .order-state {
      width: 100%;
      color: #fff;
      height: 100px;
      padding: 15px 15px;
      font-size: 16px;
      box-sizing: border-box;
      @include set-bg-left(#ff98a0, #ffb5bb, #ffa6ad);

      span {
        font-size: 16px;
        position: relative;
        top: 1px;
      }
    }

    .address {
      background: #fff;
      padding: 15px 10px;
      border-radius: 6px;
      margin: -40px 15px 15px;
      width: calc(100% - 50px);
      z-index: 12;
      box-shadow: 0 3px 10px #f2f2f2;

      .icon-dw {
        font-size: 20px;
        color: #333;
      }

      .addr-cont {
        width: calc(100% - 30px);
        display: inline-block;
        padding-left: 10px;
        vertical-align: top;

        p {
          font-size: 12px;
          color: #666;

          &:nth-child(1) {
            color: #333;

            span {
              &:nth-child(2) {
                float: right;
              }
            }
          }
        }
      }
    }

    .prod-cont-style {
      margin-bottom: 15px;
    }

    .prod-cont {
      .list-tit {
        margin: 10px 15px 1px;
        padding: 10px 15px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //border-bottom: 1px solid #f1f1f1;
        .tit-left {
          font-size: 12px;
          color: #666666;
        }

        .tit-right {
          font-size: 12px;
          color: #666666;

          span {
            color: #ff98a0;
          }
        }
      }

      .hint {
        color: #ff98a0;
        font-size: 12px;
        padding: 8px 15px;
        margin: 0 15px;
        background-color: #fff5f6;
      }
    }

    .prod-list,
    .spell-list {
      display: flex;
      align-items: center;
      font-size: 0;
      position: relative;
      margin: 0 15px;
      padding: 10px 15px;
      width: calc(100% - 60px);
      @include set-gradient-bg(#fff, #fff, #f5f5f5);

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 80px;
        height: 80px;
      }

      .prod-desc {
        width: calc(100% - 95px);
        display: inline-block;
        vertical-align: top;
        padding: 10px 0 0 15px;

        .pro-name {
          font-size: 14px;
          color: #636363;
        }

        .pro-spe {
          font-size: 12px;
          color: #636363;
        }

        .pro-val {
          font-size: 12px;
          color: #999999;
        }

        .pro-price {
          color: #ff98a0;
          font-size: 14px;
          padding-top: 15px;

          span {
            font-size: 18px;
          }
          .van-tag {
            padding: 2px 4px;
            font-size: 12px;
            display: inline-block;
            transform: scale(0.75);
          }
          .original-price {
            color: #999999;
            font-size: 10px;
            text-decoration: line-through;
          }
          .count {
            color: #999;
            float: right;
          }
        }
      }

      .prod-num {
        font-size: 14px;
        color: #b5b5b5;
        position: absolute;
        right: 15px;
        bottom: 10px;
      }
    }

    .pro-first,
    .spell-first {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      margin: 0 15px 1px;
      padding: 0 15px;
      width: calc(100% - 60px);
      background-color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .left-spe {
        display: flex;
        align-items: center;

        img {
          width: 30px;
        }

        span {
          font-size: 12px;
          color: #666666;
        }
      }

      .right-spe {
        span {
          font-size: 12px;

          &:nth-child(1) {
            color: #999999;
          }

          &:nth-child(2) {
            color: #ff98a0;
          }
        }
      }
    }
    .pro-first {
      .left-spe {
        img {
          width: 45px;
        }
      }
    }

    .text-right {
      text-align: right;
    }
  }

  .order-detail {
    color: #666;
    width: calc(100% - 30px);
    margin: 0 15px;

    .or-desc {
      background: #fff;
      padding: 15px 10px;
      margin-top: 10px;
      .van-cell {
        padding: 3px 0;
        .van-cell__title {
          flex: 1;
          color: #999;
          font-size: 12px;
        }
        .van-cell__value {
          flex: 2;
          color: #666666;
          font-size: 12px;
          text-align: left;
        }
        .font-pink {
          color: #ffb5bb;
        }
      }
      .van-divider {
        margin: 10px 0;
      }
      &.price-right {
        .van-cell {
          .van-cell__value {
            text-align: right;
          }
        }
      }
      &:first-child {
        margin-top: 0;
      }
    }

    .income {
      text-align: right;
      font-size: 12px;
      color: #333;
      padding: 5px 15px 15px;
      background: #fff;

      span {
        font-weight: 600;
      }
    }

    .approval-process {
      .van-steps {
        .van-step__title {
          line-height: 20px;
        }

        .set-round {
          .van-step__circle {
            background-color: #ff98a0 !important;
          }
        }

        .first-active {
          .van-icon-checked {
            color: #ff98a0 !important;
          }

          .van-step__title {
            color: #ff98a0 !important;
          }
        }

        .solid-line {
          .van-step__line {
            border-left: 1px solid #ccc;
            top: 16px;
          }
        }

        .cope-list {
          font-size: 12px;
          color: #333;
          padding-top: 10px;
          border-top: 1px dashed #f1f1f1;
          line-height: 20px;

          span {
            float: right;
          }
        }
      }
    }

    .coupon-detail {
      color: #999;
      padding: 10px;
      position: relative;
      border-radius: 3px;
      margin-bottom: 10px;
      border: 1px solid #ffb5bb;
      background-color: #fff9f9;

      i {
        color: #ff98a0;
      }

      &:before {
        content: '';
        position: absolute;
        top: -10px;
        right: 10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ffb5bb;
      }

      &:after {
        content: '';
        position: absolute;
        top: -9px;
        right: 11px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .pay-check-list {
    flex: 1.5;
    .van-radio-group {
      justify-content: space-between;
      .van-radio {
        margin-right: 0;
        margin-bottom: 6px;
      }
    }
  }
}


.vans-confirm {
  .van-dialog {
    left: 8%;
    width: 84%;
    margin-top: -76px;
    animation: fadeInUp 0.3s linear;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .van-dialog__footer {
    .van-button {
      border-right: 1px solid #eee;

      span {
        color: #fff;
      }

      &:active {
        &:before {
          opacity: 0;
          border-color: #fff;
          background-color: #fff;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  background: #fff;
  border-top: 1px solid #f4f4f4;
  z-index: 12;

  p {
    font-size: 12px;
    color: #333;

    span {
      color: #ff98a0;

      &.big {
        font-size: 16px;
      }
    }
  }

  .foot-btn {
    position: absolute;
    right: 15px;
    top: 8px;
    left: 120px;
    text-align: right;

    button {
      font-size: 14px;
      color: #333;
      border: 1px solid #333;
      padding: 3px 16px;
      border-radius: 30px;
      background: #fff;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      &.col {
        border-color: #ff98a0;
        color: #ff98a0;
      }
    }

    .prompt {
      width: calc(100% - 100px);
      color: #929292;
      font-size: 11px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      text-overflow: ellipsis;
      line-height: 31px;
    }
  }

  .spell-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 44px;

    .left-spell {
      height: 100%;
      display: flex;
      align-items: center;

      .img-con {
        width: 28px;
        height: 28px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        z-index: 2;

        img {
          width: 100%;
        }

        span {
          box-sizing: border-box;
          display: inline-block;
          width: 100%;
          height: 100%;
          line-height: 26px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          background-color: #f8f8f8;
          font-size: 18px;
          color: #cccccc;
          text-align: center;
        }

        &.right-con {
          z-index: 1;
          margin-left: -12px;

          span {
            border: dashed 1px #d9d9d9;
          }
        }
      }

      .remain-person {
        font-size: 12px;
        color: #666;

        > span {
          color: #ff98a0;
        }
      }

      .complete {
        font-size: 12px;
        color: #666666;

        span {
          color: #999999;
        }
      }
    }

    .invite-spell {
      border: solid 1px #ff98a0;
      color: #ff98a0;
      font-size: 12px;
      background-color: #fff;
      height: 26px;
      line-height: 26px;
      padding: 0 4%;
      -webkit-border-radius: 13px;
      -moz-border-radius: 13px;
      border-radius: 13px;
    }
  }

  .last-line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pay-money {
      font-size: 12px;
      color: #666666;

      .unit {
        font-size: 14px;
        color: #ff98a0;
      }

      .money {
        font-size: 18px;
        color: #ff98a0;
      }

      .money-remark {
        font-size: 12px;
        color: #ff98a0;
      }
    }

    button {
      //flex-shrink: 0;
      border: solid 1px #ff98a0;
      color: #ff98a0;
      font-size: 12px;
      background-color: #fff;
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      margin-right: 10px;
      border-radius: 30px;

      &:last-child {
        margin-right: 0;
      }

      &.col-gray {
        color: #999;
        border-color: #999;
      }
    }
  }
}

.gift-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.6);

  .sub-title {
    position: absolute;
    top: calc(30% - 40px);
    left: 0;
    right: 0;
    z-index: 10;
    text-align: center;
    padding: 11px 9px;
    border-radius: 12px 12px 0 0;
    background-color: #fff;

    .gift-theme {
      position: relative;
      display: inline-block;

      > span {
        z-index: 10;
        color: #ff98a0;
        font-size: 14px;
        position: relative;
        padding: 0 10px;
        background-color: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -10%;
        right: -10%;
        height: 2px;
        background: linear-gradient(
          to right,
          transparent,
          #ff98a0,
          #ff98a0,
          #ff98a0,
          transparent
        );
      }
    }

    .icon-delete {
      padding: 6px;
      position: absolute;
      top: 10px;
      right: 6px;

      &:before {
        content: '\e9c5';
      }
    }
  }

  .gift-content {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #fff;

    > img {
      width: 100%;
    }

    .g-list {
      .g-item {
        color: #666;
        padding: 5px 15px;
        display: flex;
        vertical-align: center;
        align-content: space-around;
      }
    }
  }
}

.van-step--vertical .van-step__circle {
  left: -20px;
}

.van-step .van-step__circle {
  width: 10px;
  height: 10px;
  background-color: #ccc;
}

.van-step--vertical .van-icon-checked {
  color: #ccc !important;
}

.van-step--vertical .van-step__line {
  border-left: 1px dashed #ccc;
  width: 0;
  top: 16px;
}

.icon-sl:before {
  content: '\e9e6';
}

.icon-dw:before {
  content: '\e9f0';
}

.icon--4:before {
  content: '\e9c5';
}

.icon-thin-right:before {
  content: '\ea17';
}

@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}
</style>
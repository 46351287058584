<template>
  <DetailIndex v-if="orderType === 1" :order-id="orderId"></DetailIndex>
  <!--兑换订单详情-->
  <DetailIndex v-else-if="orderType === 10" :order-id="orderId"></DetailIndex>
  <!--拼图集字兑换订单详情-->
  <PuzzleDetail v-else-if="orderType === 11" :order-id="orderId"></PuzzleDetail>
  <!--限时特惠订单详情-->
  <SpecialDetail v-else-if="orderType === 12" :order-id="orderId"></SpecialDetail>
  <!--课程订单详情-->
  <courseDetail v-else-if="orderType === 13" :order-id="orderId"></courseDetail>
  <!-- 零售兑换券专区 -->
  <RetailDetail v-else-if="orderType === 14" :order-id="orderId"></RetailDetail>
</template>

<script>
import DetailIndex from "../../components/Order/Exchange/DetailIndex";
import PuzzleDetail from "../../components/Order/PuzzleSet/DetailIndex";
import SpecialDetail from "../../components/Order/Special/DetailIndex";
import courseDetail from "../onlineCourses/DetailIndex"
import RetailDetail from "../../components/Order/Retail/DetailIndex";
import { isIos } from "../../utils/appUtil"
export default {
  name: "OrderDetail",
  components: { DetailIndex, PuzzleDetail, SpecialDetail, courseDetail, RetailDetail },
  data() {
    return {
      orderId: 0,
      orderType: -1,
    }
  },
  created() {
    if (isIos() && window.sessionStorage.getItem("payRefresh") == '1') {
      window.sessionStorage.setItem("payRefresh", '');
      window.location.reload();
    }
    let { orderType, orderId } = this.$route.query;
    this.orderId = orderId;
    this.orderType = +orderType;
  }
}
</script>

<style scoped>
</style>
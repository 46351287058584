<template>
    <div class="detail-content">
        <div class="detail-cont" v-if="orderDetail">
            <div class="order-state">
                <div style="display: flex;align-items: center">
                    <span class="icon-sl" style="margin-right: 5px"></span>
                    {{orderDetail.stateTxt}}
                </div>
            </div>
            <div class="address">
                <span class="icon-dw"></span>
                <div class="addr-cont">
                    <p><span>收货人：{{orderDetail.userName}}</span><span>{{orderDetail.phone}}</span>
                    </p>
                    <p>收货地址：{{orderDetail.address}}</p>
                </div>
            </div>
            <div class="prod-cont prod-cont-style">
                <div class="prod-list" v-for="product in orderDetail.cashdateilList">
<!--                    <img v-lazy="product.imgUrl">-->
                    <van-image style="width:124px;height: 100px" fit="contain" :src="product.imgUrl"></van-image>
                    <div class="prod-desc">
                        <p class="ellipsis1 pro-name">{{product.prizename}}</p>
                    </div>
                    <span class="prod-num">x{{product.cou}}</span>
                </div>
            </div>
            <div class="order-detail">
                <ul class="or-desc">
                    <li>
                        <span class="or-le">订单编号：</span>
                        <p>{{orderDetail.cashNum}} </p>
                    </li>
                    <li>
                        <span class="or-le">下单时间：</span>
                        <p>{{orderDetail.createTime}} </p>
                    </li>
                    <template v-if="isExpress">
                        <li v-for="de in orderDetail.expressList">
                            <div style="display: flex;justify-content: space-between;width: 100%;">
                                <span class="or-le">快递单号：{{de.expressNum}}</span>
                                <a :href="'http://m.kuaidi100.com/result.jsp?nu='+de.expressNum" class="waybillList_a">查看物流
                                    <van-icon name="arrow"/>
                                </a>
                            </div>
                        </li>
                    </template>
                </ul>
                <ul class="or-desc">
                    <li class="ivv-word">
                        <span class="or-le">合计：</span>
                        <template v-for="it in orderDetail.wordsList">
                            <span v-if="it.wordsType === 2">{{it.wordsName}}</span>
                            <img :src="it.wordsName" v-else/>
                            <i>x{{it.num}}</i>
                        </template>
                    </li>
                    <li v-if="orderDetail.payment">
                        <span class="or-le">邮费：</span>
                        <p>¥{{orderDetail.payment}}</p>
                    </li>
                </ul>

            </div>
        </div>
        <div class="footer" v-if="orderDetail">
            <div class="last-line">
                <div class="pay-money">实际支付：<span class="unit">￥</span><span
                        class="money">{{orderDetail.payment}}</span>
                </div>
                <div>
                    <button type="button" class="col-gray" @click="handleCancel" v-if="orderDetail.state === 1">
                        取消
                    </button>
                    <button type="button" @click="confirmPay" v-if="orderDetail.state === 1">
                        去付款
                    </button>
                    <button type="button" class="col-gray" @click="confirmReceipt" v-if="orderDetail.state === 3">
                        确认收货
                    </button>
                </div>
            </div>

        </div>
        <van-popup v-model="showCancelLayer">
            <Cancel @cancel="confirmCancel"/>
        </van-popup>
        <toolbar/>
    </div>
</template>

<script>
    import Detail from "../Detail"
    import {wxPay} from "../../../utils/wxPay";
    import toolbar from "../../toolbar";
    import Cancel from "../Cancel";


    export default {
        name: "ExchangeOrderDetail",
        components: {Detail, toolbar, Cancel},
        props: {
            orderId: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                orderDetail: null,
                showCancelLayer: false,
                isExpress: false
            }
        },
        created() {
            this.getOrderDetail();

        },
        methods: {
            getOrderDetail() {
                this.$toast.loading({message: '加载中...', overlay: true, duration: 0});
                this.get("/MVPuzzle/Cash/GetCashDetails", {cashId: this.orderId}, 2).then(res => {
                    this.$toast.clear();
                    if (res.code === 1) {
                        this.orderDetail = res.response;
                        this.isExpress = false
                        if (this.orderDetail.expressList && this.orderDetail.expressList.length > 0 && this.orderDetail.expressList.filter(item => !!item.expressNum).length > 0) {
                            this.isExpress = true
                        }
                    } else {
                        this.$toast(res.msg);
                    }
                })
            },
            //点击取消订单
            handleCancel(item) {
                this.$dialog.confirm({title: '提示', message: '是否确认取消该订单'}).then(() => {
                    this.currentOrder = item;
                    this.showCancelLayer = true;
                }).catch(() => {
                });
            },
            //确认取消订单
            confirmCancel(e) {
                let {type, message} = e;
                this.showCancelLayer = false;
                if (type === 1) return
                this.$toast.loading({message: '订单取消中,,,', overlay: true, duration: 0})
                this.post("/MVPuzzle/Cash/UpdateCashOrders", {
                    cashId: this.orderId,
                    type: 2,
                    reason: message
                }, true, 2).then(res => {
                    this.$toast.clear();
                    if (res.code === 1) {
                        this.$dialog.alert({title: '提示', message: '取消成功'}).then(() => {
                            window.location.reload();
                        })
                    } else {
                        this.$toast(res.msg);
                    }
                });
            },
            //确认支付
            confirmPay() {
                this.$toast.loading({message: '支付中...', overlay: true, duration: 0})
                this.post("/MVPuzzle/Cash/PayCashLog", {
                    cashId: this.orderId,
                    payType: 3
                }, true, 2).then(data => {
                    if (!data.code) {
                        this.$toast.clear();
                        return this.$dialog.alert({title: '错误', message: data.msg})
                    }
                    this.post("/MVPuzzle/Pay/WechatPayPostageWeb", {
                        applyId: data.response.applyId
                    }, true, 2).then(res => {
                        this.$toast.clear();
                        if (res.code === 1) {
                            let data = res.response;
                            wxPay({
                                appId: data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
                                paySign: data.sign, signType: data.signType, package: data.package
                            }).then(() => {
                                this.$dialog.alert({title: '提示', message: '支付成功'}).then(() => {
                                    window.location.reload();
                                })
                            }).catch(msg => {
                                this.$toast(msg);
                            })
                        } else {
                            this.$dialog.alert({title: '错误', message: res.msg,})
                        }
                    }).catch(() => {
                        this.$toast.clear();
                        this.$dialog.alert({title: '错误', message: "支付失败"})
                    })
                }).catch(() => {
                    this.$toast.clear();
                    this.$dialog.alert({title: '错误', message: "支付失败"})
                })
            },
            //点击确认收货
            confirmReceipt() {
                this.$dialog.confirm({title: '提示', message: '是否确认已收到货'}).then(() => {
                    this.$toast.loading({message: '订单确认中,,,', overlay: true, duration: 0})
                    this.post("/MVPuzzle/Cash/UpdateCashOrders", {
                        cashId: this.orderId,
                        type: 1,
                        reason: ''
                    }, true, 2).then(res => {
                        this.$toast.clear();
                        if (res.code === 1) {
                            this.$toast("确认成功!");
                            this.orderDetail.state = 4;
                            this.orderDetail.stateTxt = '已完成';
                        } else {
                            this.$toast(res.msg);
                        }
                    }).catch(() => {
                    });
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @mixin set-bg-left($bg1,$bg2,$bg3) {
        background: $bg1; /*一些不支持背景渐变的浏览器*/
        background: linear-gradient($bg2, $bg3);
        background: -moz-linear-gradient(left, $bg2, $bg3);
        background: -o-linear-gradient(left, $bg2, $bg3);
        background: -webkit-gradient(linear, left top, right top, from($bg2), to($bg3));
        background: -webkit-linear-gradient(left, $bg2, $bg3);
    }

    @mixin set-gradient-bg($bg1,$bg2,$bg3) {
        background: $bg1; /*一些不支持背景渐变的浏览器*/
        background: linear-gradient(180deg, $bg2, $bg3);
        background: -moz-linear-gradient(top bottom, circle cover, $bg2, $bg3);
        background: -o-linear-gradient(top, $bg2, $bg3);
        background: -webkit-gradient(top, from($bg2), to($bg3));
        background: -webkit-linear-gradient(top, $bg2, $bg3);

    }

    .detail-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: left;
        overflow-y: auto;
        background-color: #F8F8F8;

        .detail-cont {
            overflow-y: scroll;
            padding-bottom: 118px;

            .order-state {
                width: 100%;
                color: #fff;
                height: 100px;
                padding: 15px 15px;
                font-size: 16px;
                box-sizing: border-box;
                @include set-bg-left(#ff98a0, #ffb5bb, #ffa6ad);

                span {
                    font-size: 18px;
                    position: relative;
                    top: 1px;
                }
            }

            .address {
                background: #fff;
                padding: 15px 10px;
                border-radius: 6px;
                margin: -40px 15px 15px;
                width: calc(100% - 50px);
                z-index: 12;
                box-shadow: 0 3px 10px #f2f2f2;

                .icon-dw {
                    font-size: 20px;
                    color: #333;
                }

                .addr-cont {
                    width: calc(100% - 30px);
                    display: inline-block;
                    padding-left: 10px;
                    vertical-align: top;

                    p {
                        font-size: 12px;
                        color: #666;

                        &:nth-child(1) {
                            color: #333;

                            span {
                                &:nth-child(2) {
                                    float: right;
                                }
                            }
                        }
                    }
                }

            }

            .prod-cont-style {
                margin-bottom: 15px;
            }

            .prod-cont {
                .list-tit {
                    margin: 10px 15px 1px;
                    padding: 10px 15px;
                    background-color: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    //border-bottom: 1px solid #f1f1f1;
                    .tit-left {
                        font-size: 12px;
                        color: #666666;
                    }

                    .tit-right {
                        font-size: 12px;
                        color: #666666;

                        span {
                            color: #ff98a0;
                        }
                    }
                }

                .hint {
                    color: #ff98a0;
                    font-size: 12px;
                    padding: 8px 15px;
                    margin: 0 15px;
                    background-color: #fff5f6;
                }
            }

            .prod-list {
                display: flex;
                align-items: center;
                font-size: 0;
                position: relative;
                margin: 0 15px;
                padding: 10px 15px;
                width: calc(100% - 60px);
                @include set-gradient-bg(#fff, #fff, #f5f5f5);

                &:last-child {
                    border-bottom: none;
                }

                img {
                    width: 80px;
                    height: 80px;
                }

                .prod-desc {
                    width: calc(100% - 95px);
                    display: inline-block;
                    vertical-align: top;
                    padding: 10px 0 0 15px;

                    .pro-name {
                        font-size: 14px;
                        color: #636363;
                    }

                    .pro-spe {
                        font-size: 12px;
                        color: #636363;
                    }

                    .pro-val {
                        font-size: 12px;
                        color: #999999;
                    }

                    .pro-price {
                        color: #ff98a0;
                        font-size: 14px;
                        padding-top: 15px;

                        span {
                            font-size: 18px;
                        }
                    }
                }

                .prod-num {
                    font-size: 14px;
                    color: #b5b5b5;
                    position: absolute;
                    right: 15px;
                    bottom: 10px;
                }
            }

            .pro-first {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 44px;
                margin: 0 15px 1px;
                padding: 0 15px;
                width: calc(100% - 60px);
                background-color: #fff;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .left-spe {
                    display: flex;
                    align-items: center;

                    img {
                        width: 45px;
                    }

                    span {
                        font-size: 12px;
                        color: #666666;
                    }
                }

                .right-spe {
                    span {
                        font-size: 12px;

                        &:nth-child(1) {
                            color: #999999;
                        }

                        &:nth-child(2) {
                            color: #ff98a0;
                        }
                    }
                }
            }

            .text-right {
                text-align: right;
            }
        }

        .order-detail {
            color: #666;
            width: calc(100% - 30px);
            margin: 0 15px;


            .or-desc {
                background: #fff;
                padding: 15px 10px;

                li {
                    color: #666;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    font-size: 12px;

                    .waybillList_a {
                        color: #ff98a0;
                        display: flex;
                        align-items: center;
                    }

                    span {
                        color: #999999;
                    }

                }

                .ivv-word {
                    height: auto;
                    flex-wrap: wrap;

                    img {
                        width: 32px;
                    }

                    i {
                        padding-left: 5px;
                        font-style: normal;
                    }
                }

                &.price-right {
                    margin-top: 10px;

                    li {
                        justify-content: space-between;

                        p {
                            float: right;
                            width: auto;

                            &.red {
                                color: #ff4f42;
                            }

                            .gray {
                                color: #999;
                            }
                        }
                    }

                }

                &.pink-ul {
                    background-color: #fff0f1;
                    margin-top: 0;

                    li {
                        color: #ff98a0;

                        span {
                            color: #ff98a0;

                            &.or-le {
                                width: 160px;
                            }
                        }

                        p {
                            color: #ff98a0;
                        }
                    }

                    .other-li {
                        color: #ff98a0;
                        text-align: right;
                        font-size: 12px;
                    }

                }

                .other-li {
                    text-align: right;

                    span {
                        font-size: 16px;
                        width: auto;
                        color: #666;
                    }
                }
            }
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        background: #fff;
        border-top: 1px solid #f4f4f4;
        z-index: 12;

        p {
            font-size: 12px;
            color: #333;

            span {
                color: #ff98a0;

                &.big {
                    font-size: 16px;
                }
            }
        }

        .last-line {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pay-money {
                font-size: 12px;
                color: #666666;

                .unit {
                    font-size: 14px;
                    color: #ff98a0;
                }

                .money {
                    font-size: 18px;
                    color: #ff98a0;
                }

                .money-remark {
                    font-size: 12px;
                    color: #ff98a0;
                }

            }

            button {
                //flex-shrink: 0;
                border: solid 1px #ff98a0;
                color: #ff98a0;
                font-size: 12px;
                background-color: #fff;
                height: 28px;
                line-height: 28px;
                padding: 0 10px;
                margin-right: 10px;
                border-radius: 30px;

                &:last-child {
                    margin-right: 0;
                }

                &.col-gray {
                    color: #999;
                    border-color: #999;
                }
            }
        }
    }


    .detail-content {
        .icon-sl:before {
            content: "\e9e6";
        }

        .icon-dw:before {
            content: "\e9f0";
        }

        .icon--4:before {
            content: "\e9c5";
        }

        .icon-thin-right:before {
            content: "\ea17";
        }
    }
</style>
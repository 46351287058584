<template>
  <div>
    <Detail v-if="orderDetail" :order-detail="orderDetail" :order-type="10" @cancel="confirmCancel"
            @pay="confirmPay"/>
    <toolbar/>
  </div>
</template>

<script>
import Detail from "../Detail"
import {wxPay} from "../../../utils/wxPay";
import toolbar from "../../toolbar";

export default {
  name: "ExchangeOrderDetail",
  components: {Detail, toolbar},
  props: {
    orderId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      orderDetail: null
    }
  },
  created() {
    this.getOrderDetail();

  },
  methods: {
    getOrderDetail() {
      this.$toast.loading({message: '加载中...', overlay: true, duration: 0});
      this.get("/OrderGoods/FixedExchange/GetFixedExchangeOrderDetails", {orderId: this.orderId}, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let totalNum = 0, data = res.response;
          this.orderDetail = {
            ProductList: (() => {
              let arr = [];
              data.goodsList.forEach(it => {
                arr.push({
                  ImageUrl: it.goodsImg,
                  GoodsName: it.goodsName,
                  Amount: it.goodsPrice,
                  Count: it.goodsCount,
                  // Effect: it.goodsActEffect,
                  Specifications: it.goodsSpecifications
                })
                totalNum += it.goodsCount
              })
              return arr;
            })(),
            stateTest: data.stateTxt,
            totalNum: totalNum,
            OrderUserName: data.name,
            overdueTimes: data.overdueTimes,
            OrderUserPho: data.phone,
            OrderAddress: data.fullAddress,
            OrderNum: data.orderNum,
            OrderDateTime: data.createTimes,
            SellerMessage: data.remark,
            payTypeText: '在线支付',
            orderRealPrice: data.payAmount,
            cancelReason: data.cancelReasons,
            payResultTxt: data.state === 0 ? '待付款' : '实付款',
            btnList: this.initBtnList(data.state),
            OrderTotalPrice: data.totalAmount,
            Postage: data.postage,
            RedBagBalance: data.redBagBalance,
            waybillList: data.waybillList
          };
        } else {
          this.$toast(res.msg);
        }
      })
    },
    initBtnList(state) {
      let arr = [];
      if ([0, 10].indexOf(state) >= 0) arr.push(
          {text: '取消订单', type: 1, className: 'col-gray', showType: 1}
      );
      if (state === 0) arr.push({text: '去付款', type: 2, className: '', showType: 1});
      else if (state === 20) arr.push({text: '待发货', type: 1, className: '', showType: 2});
      else if (state === 30) arr.push({text: '已发货', type: 1, className: '', showType: 2});
      else if (state === 99) arr.push({text: '已取消', type: 1, className: '', showType: 2});
      else if (state === 100) arr.push({text: '已完成', type: 1, className: '', showType: 2});
      return arr;
    },
    //确认取消订单
    confirmCancel(e) {
      let {type, message} = e;
      this.showCancelLayer = false;
      if (type === 1) return
      this.$toast.loading({message: '订单取消中,,,', overlay: true, duration: 0})
      this.post("/OrderGoods/FixedExchange/CancelFixedExchangeOrderV2", {
        orderId: this.orderId,
        reasons: message
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.$toast("取消成功!");
          this.orderDetail.state = 99;
          this.orderDetail.stateTest = "已取消";
          this.orderDetail.btnList = this.initBtnList(99);
          this.orderDetail.cancelReason = message;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //确认支付
    confirmPay() {
      this.$toast.loading({message: '支付中...', overlay: true, duration: 0})
      this.post("/OrderGoods/FixedExchange/PayFixedExchangeOrder", {
        orderId: this.orderId,
        payType: 1
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response;
          wxPay({
            appId: data.appid, timeStamp: data.timeStamp, nonceStr: data.nonceStr,
            paySign: data.sign, signType: data.signType, package: data.package
          }).then(() => {
            // this.$toast("支付成功");
            this.orderDetail.state = 10;
            this.orderDetail.stateTest = "待发货";
            this.orderDetail.btnList = this.initBtnList(10);
            this.$router.push("/zero/success/" + this.orderId + "?orderNum=" + this.orderDetail.OrderNum)
          }).catch(msg => {
            this.$toast(msg);
          })
        } else {
          this.$dialog.alert({
            title: '错误',
            message: res.msg,
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div id="toolbar">
    <div v-if="!show" @click="show = !show" class="title">
      <div style="margin-right: 5px">
        <div>快速</div>
        <div>导航</div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div v-if="show" class="cover">
      <div class="toolbar_box">
        <div class="white_container">
          <div @click="goHomePage('goHome')" class="toolbar_item">
            <van-icon color="#999" size="25" name="home-o" />
            <div>首页</div>
          </div>
          <!-- /Tool/html/index.html? -->
          <div @click="routerChange('','jumpTools')" class="toolbar_item">
            <van-icon color="#999" size="25" name="eye-o" />
            <div>工具箱</div>
          </div>
          <div @click="routerChange('/Arongleweb/html/groupon/shop_cart.html','jumpShopkeeperCar')" style="margin-right: 0" class="toolbar_item">
            <van-icon color="#999" size="25" name="shopping-cart-o" />
            <div>购物车</div>
          </div>
          <div @click="routerChange('/Arongleweb/html/mycenter/manage.html','jumpMine')" class="toolbar_item">
            <van-icon color="#999" size="25" name="user-o" />
            <div>我的</div>
          </div>
          <div @click="back" style="transform: translateX(-10px)" class="toolbar_item">
            <van-icon color="#999" size="25" name="revoke" />
            <div>返回上一页</div>
          </div>
        </div>
        <div @click="show = !show" class="hide_btn">
          <van-icon name="arrow-left" />
          <div style="transform: translateY(-1px)">收起</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deliveryMessage } from "../utils/appUtil";
export default {
  name: "toolbar",
  props: {
    token: {
      type: String,
      default: ''
    },
    backStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    }
  },
  created() { },
  methods: {
    routerChange(path, action) {
      if (this.token && action) {
        return deliveryMessage({ 'action': 'goHome' })
      }
      if (!path) return this.$toast('升级中，敬请期待！');
      window.location.href = this.baseHost + '/7999' + path
    },
    back() {
      if (this.backStatus) return this.$emit("back");
      this.$router.back()
    },
    goHomePage(action) {
      if (this.token) {
        return deliveryMessage({ 'action': 'goHome' })
      }
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
#toolbar {
  font-size: 12px;
  @keyframes show {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .toolbar_box {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10000;
    left: 0;
    bottom: 50px;
    animation: show 0.8s forwards;
    .hide_btn {
      height: 40px;
      border-radius: 0 500px 500px 0;
      display: flex;
      align-items: center;
      color: #fff;
      width: 50px;
      background-color: rgba(0, 0, 0, 0.7);
      justify-content: center;
      box-sizing: border-box;
      padding-right: 5px;
    }
    .white_container {
      box-sizing: border-box;
      padding-left: 15px;
      padding-top: 3px;
      width: 200px;
      height: 100px;
      border-radius: 0 6px 6px 0;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      .toolbar_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 35px;
        div {
          /*font-size: 1px;*/
          color: #999;
        }
      }
    }
  }
  .cover {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .title {
    position: fixed;
    bottom: 100px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 500px 500px 0;
    display: flex;
    color: #fff;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 99;
  }
}
</style>